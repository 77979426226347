//--------------------------------------------------------------------------
// Typo
//--------------------------------------------------------------------------

=font($var1: '', $var2: '')
  @if $var1 == 'light' or $var2 == 'light'
    font-family: $font-family-light
  @else if $var1 == 'regular' or $var2 == 'regular'
    font-family: $font-family-regular
  @else if $var1 == 'italic' or $var2 == 'italic'
    font-family: $font-family-italic
  @else if $var1 == 'semibold' or $var2 == 'semibold'
    font-family: $font-family-semibold
  @else if $var1 == 'bold' or $var2 == 'bold'
    font-family: $font-family-bold

  @if $var1 == 'tiny' or $var2 == 'tiny'
    font-size: $font-size-tiny
    line-height: $line-height-tiny
  @else if $var1 == 'small' or $var2 == 'small'
    font-size: $font-size-small
    line-height: $line-height-small
  @else if $var1 == 'default' or $var2 == 'default'
    font-size: $font-size-default
    line-height: $line-height-default
  @else if $var1 == 'medium' or $var2 == 'medium'
    font-size: $font-size-medium
    line-height: $line-height-medium
  @else if $var1 == 'big' or $var2 == 'big'
    font-size: $font-size-big
    line-height: $line-height-big
  @else if $var1 == 'bigger' or $var2 == 'bigger'
    font-size: $font-size-bigger
    line-height: $line-height-bigger
  @else if $var1 == 'jumbo' or $var2 == 'jumbo'
    font-size: $font-size-jumbo
    line-height: $line-height-jumbo
  
//--------------------------------------------------------------------------
// Icons
//--------------------------------------------------------------------------
  
=icon($name: null, $position: 'before')
  @if $position == 'after'
    &:after
      font-family: $font-family-icons !important
      font-style: normal !important
      font-weight: normal !important
      font-variant: normal !important
      text-transform: none !important
      color: inherit
      speak: none
      line-height: 1
      -webkit-font-smoothing: antialiased
      -moz-osx-font-smoothing: grayscale
      @if $name
        content: map-get($icons, $name)
  @else
    &:before
      font-family: $font-family-icons !important
      font-style: normal !important
      font-weight: normal !important
      font-variant: normal !important
      text-transform: none !important
      color: inherit
      speak: none
      line-height: 1
      -webkit-font-smoothing: antialiased
      -moz-osx-font-smoothing: grayscale
      @if $name
        content: map-get($icons, $name)
    

//--------------------------------------------------------------------------
// Measure and distance helper
//--------------------------------------------------------------------------

@function content-width ($breakpoint: 'lg')
  $key: breakpoint($breakpoint)
  @return nth($content-width, $key) + (2 * nth($content-padding, $key))

@function content-padding ($breakpoint: 'lg')
  $key: breakpoint($breakpoint)
  @return nth($content-padding, $key)

@function col-gap ($breakpoint: 'lg')
  $key: breakpoint($breakpoint)
  @return nth($col-gap, $key)

@function font-size ($breakpoint: 'lg')
  $key: breakpoint($breakpoint)
  @return nth($font-size, $key)

@function m ($num: 1)
  @return $measure * $num

@function breakpoint($name: 'lg')
  @if $name == 'md'
    @return 2
  @else if $name == 'sm'
    @return 3
  @else if $name == 'xs'
    @return 4
  @else
    @return 1

//--------------------------------------------------------------------------
// Helper
//--------------------------------------------------------------------------

=unselectable
  -webkit-touch-callout: none
  -webkit-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none

//--------------------------------------------------------------------------
// Elements
//--------------------------------------------------------------------------

=loader
  animation: spinAround 500ms infinite linear
  border: 2px solid $grey-lighter
  border-radius: $radius-rounded
  border-right-color: transparent
  border-top-color: transparent
  content: ""
  display: block
  height: 1em
  position: relative
  width: 1em

=placeholder
  $placeholders: ':-moz' ':-webkit-input' '-moz' '-ms-input'
  @each $placeholder in $placeholders
    &:#{$placeholder}-placeholder
      @content

//--------------------------------------------------------------------------
// Responsive
//--------------------------------------------------------------------------

=from($width)
  @media screen and (min-width: $width + 1px)
    @content

=until($width)
  @media screen and (max-width: $width)
    @content

=lg
  $max: map-get($breakpoints, 'lg')
  @media screen and (max-width: #{$max}px)
    @content

=lg-only
  $min: map-get($breakpoints, 'md') + 1
  $max: map-get($breakpoints, 'lg')
  @media screen and (min-width: #{$min}px) and (max-width: #{$max}px)
    @content

=md
  $max: map-get($breakpoints, 'md')
  @media screen and (max-width: #{$max}px), print
    @content

=md-only
  $min: map-get($breakpoints, 'sm') + 1
  $max: map-get($breakpoints, 'md')
  @media screen and (min-width: #{$min}px) and (max-width: #{$max}px)
    @content

=sm
  $max: map-get($breakpoints, 'sm')
  @media screen and (max-width: #{$max}px)
    @content

=sm-only
  $min: map-get($breakpoints, 'xs') + 1
  $max: map-get($breakpoints, 'sm')
  @media screen and (min-width: #{$min}px) and (max-width: #{$max}px)
    @content

=xs
  $max: map-get($breakpoints, 'xs')
  @media screen and (max-width: #{$max}px)
    @content

=xs-only
  $max: map-get($breakpoints, 'xs')
  @media screen and (max-width: #{$max}px)
    @content

//--------------------------------------------------------------------------
// Shadows
//--------------------------------------------------------------------------

=soft-shadow
  box-shadow: 0 2px 10px rgba(0,0,0,.2)