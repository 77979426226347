@charset "utf-8"

@import "../../styles/lib/variables.sass"
@import "../../styles/lib/mixins.sass"































































































.events-elem
  position: relative
  .event
    margin-bottom: m(3)
    transition: opacity 1s ease
    &:last-child
      margin-bottom: 0
    .info
      padding-left: col-gap('lg')
      .eventtype
        margin-bottom: m(1)
        text-transform: uppercase
        +font('bold', 'small')
        .sold
          margin-left: m(1)
          color: $warning-color
      .datetime
        margin-top: m(1)
        .date
          +font('medium')
      .speaker
        margin-top: m(1)
        width: 80%
      .button
        margin-top: m(3)
    .image
      img
        margin-top: 4px
  .empty-result
    margin: m(5) 0
    +font('italic')
    color: $grey-light
    text-align: center
  .spinner-elem
    display: none
    top: m(3)
    left: 50%
    transform: translateX(-50%)
  &.is-loading
    .event
      opacity: 0
    .spinner-elem
      display: block

+md
  .events-page
    .event
      .info
        padding-left: col-gap('md')

+sm
  .events-page
    .event
      .info
        padding-left: col-gap('sm')

+xs
  .events-page
    .event
      .info
        padding-left: col-gap('xs') / 2
