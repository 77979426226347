//--------------------------------------------------------------------------
// Import some variables
//--------------------------------------------------------------------------
  
@import "../../config/style.json"

//--------------------------------------------------------------------------
// Typo
//--------------------------------------------------------------------------

$font-family-light:               titillium_light, sans-serif
$font-family-regular:             titillium_regular, sans-serif
$font-family-italic:              titillium_italic, sans-serif
$font-family-semibold:            titillium_medium, sans-serif
$font-family-bold:                titillium_bold, sans-serif
$font-family-monospace:           monospace
$font-family-icons:               hausvier_icons

// use font-size('xs')
$font-size:                       18px, 18px, 17px, 16px

$font-size-tiny:                  0.8rem
$font-size-small:                 .9rem
$font-size-default:               1rem // p, h3
$font-size-medium:                1.3rem // blockquote
$font-size-big:                   1.5rem // h2
$font-size-bigger:                1.8rem // h1
$font-size-jumbo:                 2.6rem

$line-height-tiny:                1.5
$line-height-small:               1.5
$line-height-default:             1.6
$line-height-medium:              1.5
$line-height-big:                 1.3
$line-height-bigger:              1.3
$line-height-jumbo:               1.2

//--------------------------------------------------------------------------
// Colors
//--------------------------------------------------------------------------

$black:                           hsl(0, 0%, 3%)
$black-light:                     hsl(0, 0%, 6%)
$black-lighter:                   hsl(0, 0%, 14%)
$grey-darker:                     hsl(0, 0%, 21%)
$grey-dark:                       hsl(0, 0%, 29%)
$grey:                            hsl(0, 0%, 48%)
$grey-light:                      hsl(0, 0%, 71%)
$grey-lighter:                    hsl(0, 0%, 86%)
$white-darker:                    hsl(0, 0%, 96%)
$white-dark:                      hsl(0, 0%, 98%)
$white:                           hsl(0, 0%, 100%)

$orange:                          hsl(30,  88%, 56%)
$yellow:                          hsl(49,  73%, 42%)
$green:                           hsl(120, 46%,  41%)
$turquoise:                       hsl(171, 100%, 41%) // nicht verwendet
$cyan:                            hsl(204, 86%,  53%) // nicht verwendet
$blue:                            hsl(206, 64%,  41%)
$purple:                          hsl(294, 27%, 52%)
$red:                             hsl(348, 100%, 43%) // nicht verwendet

//--------------------------------------------------------------------------
// Element and Text colors
//--------------------------------------------------------------------------

$primary-color:                   $blue
$seconday-color:                  $purple
$warning-color:                   $red
$success-color:                   $green

$text-color:                      $grey-dark
$subtext-color:                   $grey
$headline-color:                  $grey-dark
$link-color:                      $green
$quote-color:                     $grey

$link-active-color:               $red
$body-color:                      $white
$content-color:                   $white
$border-color:                    $grey-lighter

$opacity-soft:                    .75
$opacity-softer:                  .65

//--------------------------------------------------------------------------
// Responsive
//--------------------------------------------------------------------------

// Values given are MAX-width of the device:
//
// 1441 - *  lg / widescreen
// 1440 - 835 is md / desktop (and tablet landscape mode)
// 834 - 481 is sm / tablet (portrait mode and mobile landscape mode)
// 480 - 0 is xs / mobile (for portrait mode only)
//
// https://css-tricks.com/snippets/css/media-queries-for-standard-devices/
//
// Breakpoints are set to rounded value in maximum, because maximum widths
// of pictures etc. can be computed better.

//--------------------------------------------------------------------------
// Dimensions, padding, margin
//--------------------------------------------------------------------------

// content-width + 2 x content-margin = width of content block
// use content-width('xs')
$content-width:                   1064px, 1064px, 920px, 760px

// left and right space between body and content
// use content-padding('xs')
$content-padding:                 30px, 30px, 24px, 18px

// space between columns, dividable by 2
// use col-gap('xs')
$col-gap:                         14px, 14px, 12px, 10px

// horizontal and vertical distance between text-blocks
// padding for textblocks with border or background
// use function: padding: m(3)
$measure:                         .4rem

// hamburger bar on top, including padding
$nav-toolbar-height:              3rem

//--------------------------------------------------------------------------
// Borders, Radius
//--------------------------------------------------------------------------

$radius-small:                    3px
$radius:                          5px
$radius-large:                    8px
$radius-rounded:                  290486px

//--------------------------------------------------------------------------
// Buttons
//--------------------------------------------------------------------------

$button-height:                   2.4em // em, not rem, because different sizes

//--------------------------------------------------------------------------
// Form
//--------------------------------------------------------------------------
  
$form-font-size:                 $font-size-default
$form-line-height:               $line-height-medium
$form-textarea-height:           8rem
$form-input-indent:              .3rem
$form-color:                     $grey-darker
$form-background-color:          $white
$form-disabled-color:            $grey
$form-disabled-background-color: $white-darker
$form-error-color:               $warning-color
$form-placeholder-color:         $grey-light
$form-border-color:              $grey-light
$form-border-radius:             $radius-small
$form-control-color:             $blue

//--------------------------------------------------------------------------
// Form
//--------------------------------------------------------------------------

$z-index-base:                   100
$z-index-slider:                 700
$z-index-overlay:                800
$z-index-dialog:                 900
